<template>
  <a-card>
    <div slot="title">训练计划-技能包阶段信息</div>
    <basis-form ref="basis" type="plan" :mainFieldId="NaN" :fieldId="NaN" :origin="from" @initData="initData" />
    <a-row>
      <a-col v-bind="$formItemLayoutWithoutLabel.wrapperCol">
        <a-button :loading="loading" type="primary" shape="round" @click="onSubmit" block>
          <span>保存</span>
        </a-button>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import events from '@/components/MultiTab/events'
import * as planApi from '@/api/plan'
import BasisForm from '@/views/skill-management/skill/phase/components/BasisForm'
import { mapActions } from 'vuex'

export default {
  name: 'ServiceManagementPlanSkillPhaseEdit',
  components: {
    BasisForm
  },
  data() {
    return {
      loading: false,
      // 来源（为家校训练服务-详情-训练计划-编辑）,来源唯一且固定
      from: '',
      planSkillId: null, // 编辑的技能包
      activeKey: null // 来源的页签值
    }
  },
  watch: {
    $route() {
      const {
        $route: { name },
        initData
      } = this

      if (name === 'ServiceManagementPlanSkillPhaseEdit') {
        initData()
      }
    }
  },
  created() {
    const {
      $route: {
        query: { from, activeKey, planSkillId }
      }
    } = this
    // 获取路由内的参数
    this.from = from
    this.activeKey = activeKey
    this.planSkillId = planSkillId
  },
  mounted() {
    this.initData()
  },
  methods: {
    ...mapActions(['changeSpinning']),
    initData() {
      const {
        $route: {
          params: { id },
          query: { studentId }
        },
        $refs: { basis }
      } = this
      this.changeSpinning(true)
      planApi.phaseDetail(id).then(res => {
        basis.planinitData(res.data, studentId)
      })
    },
    onSubmit() {
      const {
        $route: {
          params: { skillId, id }
        },
        $refs: { basis },
        $notification,
        close
      } = this

      basis.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          try {
            const { planformatForm } = basis
            const form = planformatForm()
            form.skillId = skillId
            form.id = id

            planApi
              .updatePhase(form)
              .then(res => {
                $notification['success']({ message: '操作成功' })
                close()
              })
              .finally(() => (this.loading = false))
          } catch (error) {
            $notification['error']({ message: '提示', description: '脚本错误' })
            this.loading = false
          }
        }
      })
    },
    close() {
      const { activeKey, planSkillId } = this
      events.$emit('close')
      // 保存在本地，回到家校服务-训练计划下，滚动到对应的技能包
      sessionStorage.setItem('activeKey', activeKey)
      sessionStorage.setItem('planSkillId', planSkillId)
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped></style>
